import { JZ } from "@buzzbike/ui/src/DesignSystem";
import { chakra, HStack, Stack, Text } from "@chakra-ui/react";
import { useCities } from "hooks/useCities";
import { useSegment } from "hooks/useSegment";
import { useRouter } from "next/router";

import SignupLayout from "components/newUI/SignupLayout";
import StyledSecondaryButton from "components/StyledSecondaryButton";
import {
  SignupFunnelStep,
  trackSeletedCity,
  useTrackSignupFunnelStepStarted,
} from "utils/tracking";

import {
  getCheckoutPageHandler,
  updateCheckoutCookiesFromClient,
} from "../../utils/checkout-cs";

export const getServerSideProps = getCheckoutPageHandler("city");

export default function Page() {
  const router = useRouter();
  const segment = useSegment();

  const { data: citiesData } = useCities();

  useTrackSignupFunnelStepStarted(SignupFunnelStep.City);

  const handleOnContinue = async (cityId: number) => {
    const city = citiesData?.cities.find((i) => i.id === cityId);
    trackSeletedCity(segment, city?.name || "London");
    await updateCheckoutCookiesFromClient("checkout", {
      cityId,
      validPostcode: {
        postalAreaList: city?.postalAreaList || undefined,
        postalDistrictList: city?.postalDistrictList || undefined,
      },
    });
    await router.push({
      pathname: "60-plan",
      query: {
        ...router.query,
      },
    });
  };

  return (
    <SignupLayout step="step0" isCyclescheme>
      <Stack
        maxW={"xl"}
        spacing={4}
        margin="auto"
        justifyContent={"center"}
        minH="60vh"
        textAlign="left"
      >
        <Text {...JZ["Web/Title/40 Semi"]} fontSize={32} color={JZ.BuzzBlack}>
          Where do you live?
        </Text>
        <Stack direction={["column", "row"]}>
          {citiesData?.cities
            .map(({ id, name }) => (
              <StyledSecondaryButton
                key={`${id}_${name}`}
                flex={1}
                minH={14}
                onClick={() => handleOnContinue(id)}
              >
                <HStack alignItems="center">
                  <Text {...JZ["Web/Body/20 Med"]}>{name}</Text>
                  <chakra.span
                    {...JZ["Body/18 Reg"]}
                    fontSize={16}
                    fontWeight="light"
                  >
                    (Greater)
                  </chakra.span>
                </HStack>
              </StyledSecondaryButton>
            ))
            .reverse()}
        </Stack>
      </Stack>
    </SignupLayout>
  );
}
